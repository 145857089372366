import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _34ee2b4c = () => interopDefault(import('../pages/apoverlags-newsletterarchiv/index.vue' /* webpackChunkName: "pages/apoverlags-newsletterarchiv/index" */))
const _415a6334 = () => interopDefault(import('../pages/buch-aktuell/index.vue' /* webpackChunkName: "pages/buch-aktuell/index" */))
const _2a1377b0 = () => interopDefault(import('../pages/newsletter/index.vue' /* webpackChunkName: "pages/newsletter/index" */))
const _18f6c9ca = () => interopDefault(import('../pages/registrierung/index.vue' /* webpackChunkName: "pages/registrierung/index" */))
const _681ee8fc = () => interopDefault(import('../pages/suche.vue' /* webpackChunkName: "pages/suche" */))
const _3a9cf242 = () => interopDefault(import('../pages/produkte/fortbildung.vue' /* webpackChunkName: "pages/produkte/fortbildung" */))
const _1e745a5b = () => interopDefault(import('../pages/produkte/medien.vue' /* webpackChunkName: "pages/produkte/medien" */))
const _aafc4a88 = () => interopDefault(import('../pages/produkte/software.vue' /* webpackChunkName: "pages/produkte/software" */))
const _5aef6b56 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _19a65325 = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _78f2c562 = () => interopDefault(import('../pages/author/_id.vue' /* webpackChunkName: "pages/author/_id" */))
const _23443802 = () => interopDefault(import('../pages/channel/_slug.vue' /* webpackChunkName: "pages/channel/_slug" */))
const _ed9bdbd0 = () => interopDefault(import('../pages/product-inquiry/_id.vue' /* webpackChunkName: "pages/product-inquiry/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/apoverlags-newsletterarchiv",
    component: _34ee2b4c,
    name: "apoverlags-newsletterarchiv"
  }, {
    path: "/buch-aktuell",
    component: _415a6334,
    name: "buch-aktuell"
  }, {
    path: "/newsletter",
    component: _2a1377b0,
    name: "newsletter"
  }, {
    path: "/registrierung",
    component: _18f6c9ca,
    name: "registrierung"
  }, {
    path: "/suche",
    component: _681ee8fc,
    name: "suche"
  }, {
    path: "/produkte/fortbildung",
    component: _3a9cf242,
    name: "produkte-fortbildung"
  }, {
    path: "/produkte/medien",
    component: _1e745a5b,
    name: "produkte-medien"
  }, {
    path: "/produkte/software",
    component: _aafc4a88,
    name: "produkte-software"
  }, {
    path: "/",
    component: _5aef6b56,
    name: "index"
  }, {
    path: "/article/:id?",
    component: _19a65325,
    name: "article-id"
  }, {
    path: "/author/:id?",
    component: _78f2c562,
    name: "author-id"
  }, {
    path: "/channel/:slug?",
    component: _23443802,
    name: "channel-slug"
  }, {
    path: "/product-inquiry/:id?",
    component: _ed9bdbd0,
    name: "product-inquiry-id"
  }, {
    path: "/bestellen/:id(\\d+)",
    component: _ed9bdbd0,
    name: "order-canonical"
  }, {
    path: "/autor/:authorName([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _78f2c562,
    name: "author-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)+/:slug([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _19a65325,
    name: "article-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)*/:slug([a-z0-9A-F%-]+)",
    component: _23443802,
    name: "channel-canonical"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
